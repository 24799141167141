// @flow
import * as React from "react";
import Image from "next/image";
import Link from "next/link";

export default function DestinationCard1(props) {
  return (
    <div className="col-sm-6">
      <div className="desti-item overlay-desti-item">
        <Link
          href={`/destinations/${props.name
            .replace(/[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi, "")
            .replace(/\s+/g, " ")
            .replace(" ", "-")
            .split(" ")
            .join("-")}`}
        >
          <figure className="desti-image" style={{ cursor: "pointer" }}>
            <img
              alt=""
              src={props.img}
              style={{ height: "590px", objectFit: "cover" }}
            />
          </figure>
        </Link>
        <div className="meta-cat bg-meta-cat">
          <Link
            href={`/destinations/${props.name
              .replace(/[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi, "")
              .replace(/\s+/g, " ")
              .replace(" ", "-")
              .split(" ")
              .join("-")}`}
          >
            <a>{props.name}</a>
          </Link>
        </div>
        <div className="desti-content"></div>
      </div>
    </div>
  );
}

// @flow
import * as React from "react";
import Link from "next/link";

export default function PopularPackageCard(props) {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="package-wrap">
        <figure className="feature-image">
          <Link
            href={`/package/${props.name
              .replace(/[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi, "")
              .replace(/\s+/g, " ")
              .replace(" ", "-")
              .split(" ")
              .join("-")}`}
          >
            <a>
              <img alt="" src={props.img} />
            </a>
          </Link>
        </figure>
        {props.price !== null &&
          props.price?.length > 0 &&
          props.price !== "0" && (
            <div className="package-price">
              <h6>
                <span>${props.price} </span> / per person
              </h6>
            </div>
          )}
        <div className="package-content-wrap">
          <div className="package-meta text-center">
            <ul>
              <li>
                <i className="fas fa-map-marker-alt"></i>
                {props.city.map((city) => {
                  return (
                    <>
                      <span> {city.cityName}</span>
                    </>
                  );
                })}
              </li>
            </ul>
          </div>
          <div className="package-content">
            <h3>
              <Link
                href={`/package/${props.name
                  .replace(/[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi, "")
                  .replace(/\s+/g, " ")
                  .replace(" ", "-")
                  .split(" ")
                  .join("-")}`}
              >
                <a>{props.name}</a>
              </Link>
            </h3>

            <p
              dangerouslySetInnerHTML={{ __html: props.description }}
              style={{ maxHeight: "70px", overflowY: "hidden" }}
            ></p>
            <div className="btn-wrap">
              <Link
                href={`/package/${props.name
                  .replace(/[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi, "")
                  .replace(/\s+/g, " ")
                  .replace(" ", "-")
                  .split(" ")
                  .join("-")}`}
              >
                <a className="button-text width-6">
                  Read More<i className="fas fa-arrow-right"></i>
                </a>
              </Link>
              <a className="button-text width-6">
                {props.country} <i className="far map-arrow-alt"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

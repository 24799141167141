// @flow
import * as React from "react";

export default function TestimonialCard(props) {
  return (
    <div
      className="text-center"
      style={{ padding: "1.5rem", fontSize: "16px" }}
    >
      <div className="testimonial-content">
        <p
          dangerouslySetInnerHTML={{ __html: props.description }}
          style={{
            lineHeight: "1.5",
            fontSize: "1.3rem",
            fontStyle: "italic",
            color: "#151515",
            height: "100px",
            overflowY: "hidden",
          }}
        ></p>
        <cite>
          {" "}
          {props.name}
          {/* <span className="company"><i className="fas fa-calendar"></i> {props.date}</span> */}
        </cite>
        <div className="my-3">
          <i className="far fa-calendar mr-2"></i>
          <span>
            {`${new Date(props.createdDate).toLocaleString("en", {
              day: "numeric",
            })}`}{" "}
          </span>
          <span>
            {" "}
            {`${new Date(props.createdDate).toLocaleString("en", {
              month: "long",
            })}`}{" "}
          </span>
          <span>{`${new Date(props.createdDate).toLocaleString("en", {
            year: "numeric",
          })}`}</span>
        </div>
      </div>
    </div>
  );
}

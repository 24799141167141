import * as React from "react";
import Link from "next/link";

export default function DestinationCard2(props) {
  return (
    <div className="col-md-6 col-xl-12">
      <div className="desti-item overlay-desti-item">
        <Link
          href={`/destinations/${props.name
            .replace(/[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi, "")
            .replace(/\s+/g, " ")
            .replace(" ", "-")
            .split(" ")
            .join("-")}`}
        >
          <figure
            className="desti-image"
            style={{ cursor: "pointer", objectFit: "cover" }}
          >
            <img alt="" src={props.img} />
          </figure>
        </Link>
        <div className="meta-cat bg-meta-cat">
          <Link
            href={`/destinations/${props.name
              .replace(/[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi, "")
              .replace(/\s+/g, " ")
              .replace(" ", "-")
              .split(" ")
              .join("-")}`}
          >
            <a>{props.name}</a>
          </Link>
        </div>
        <div className="desti-content"></div>
      </div>
    </div>
  );
}

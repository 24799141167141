// @flow
import * as React from "react";
import Link from "next/link";

export default function BlogCard(props) {
  {
    console.log("props", props);
  }
  return (
    <div className="col-md-6 col-lg-4">
      <article className="post">
        <figure className="feature-image">
          <Link href={`/Article/${props.pageURL}`}>
            <a>
              <img alt="" src={props.img ? props.img : ""} />
            </a>
          </Link>
        </figure>
        <div className="entry-content">
          <h3>
            <Link href={`/Article/${props.pageURL}`}>
              <a>{props.content}</a>
            </Link>
          </h3>
          {/* <div className="entry-meta">

                        <p className="posted-on">
                            <a>
                            <span>{`${new Date(props.date).toLocaleString('en', { month: 'long'})}`}</span>
                            <span>{`${new Date(props.date).toLocaleString('en', { day: 'numeric'})}`}</span>
                            <span>{`${new Date(props.date).toLocaleString('en', { year: 'numeric'})}`}</span>
                            </a>
                        </p>                          
                    </div> */}
        </div>
      </article>
    </div>
  );
}

import Head from "next/head";
import Image from "next/image";
import Slider from "react-slick";
import DestinationCard1 from "../components/layout/home/destination-card1";
import DestinationCard2 from "../components/layout/home/destination-card2";
import PopularPackageCard from "../components/layout/home/popular-package-card";
import BrochuresCard from "../components/layout/home/brochures-card";
import BlogCard from "../components/layout/home/blog-card";
import TestimonialCard from "../components/layout/home/testimonial-card";
import ClientSection from "../components/sections/client-section";
import ContentSection from "../components/sections/content-section";
import SubscribeSection from "../components/sections/subscribe-section";
import axiosconfig from "../config/axios-config";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import classes from "../styles/home.module.css";

export default function Home(props) {
  const homeSlider = {
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 1200,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
  };

  const testimonialSlider = {
    dots: true,
    infinite: true,
    autoplay: false,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: false,
  };

  const [pagecontainer, setPagecontainer] = useState({});
  const [packageTypes, setPackageTypes] = useState([]);
  const [brochuresArray, setBrochures] = useState([]);
  const [articlesArray, setArticles] = useState([]);
  const [testimonialsArray, setTestimonials] = useState([]);
  const [contactus, setContactus] = useState({});

  const getpagecontainer = async () => {
    const page = await axiosconfig.get("website/pagecontainer", {
      params: {
        PageModuleId: props.pageSections.data[0].aboutUsData?.pageContainerId,
      },
    });
    const pagecontainer = await page.data.data[0];
    console.log(pagecontainer);
    setPagecontainer(pagecontainer);
  };
  async function getpackageTypes() {
    const packageType = await axiosconfig.get("foundation/packagetype", {
      params: {
        PageSize: 50,
      },
    });

    const packageTypes = await packageType.data.data;
    setPackageTypes(packageTypes);
  }
  const getBrochures = async () => {
    const bro = await axiosconfig.get("website/brochure/mini", {
      params: {
        PageSize: 500,
      },
    });
    const brochures = await bro.data;
    setBrochures(brochures.data);
  };
  const getArticles = async () => {
    const art = await axiosconfig.get("website/article/mini", {
      params: {
        PageSize: 500,
      },
    });
    const articles = await art.data;
    setArticles(articles.data);
  };
  const getTestimonials = async () => {
    const test = await axiosconfig.get("website/testimonial/mini", {
      params: {
        PageSize: 500,
        Status: "accepted",
        IsActive: true,
      },
    });
    const testimonials = await test.data;
    setTestimonials(testimonials.data);
  };
  const getContactus = async () => {
    const contact = await axiosconfig.get("website/contactus");

    const contactus = await contact.data;
    setContactus(contactus.data[0]);
  };

  useEffect(() => {
    getpagecontainer();
    getpackageTypes();
    getBrochures();
    getArticles();
    getTestimonials();
    getContactus();
  }, []);

  var destinations = [...props.destinations.data];
  var destShow = [];
  destinations.forEach((dest) => {
    if (dest.isHomeShown == true) {
      destShow.push(dest);
    }
  });

  var Testimonials = [...testimonialsArray];
  var testShow = [];
  Testimonials.forEach((test) => {
    if (test.isHomeShown == true) {
      testShow.push(test);
    }
  });

  var packageandweb = [];
  const packages = [...props.packages.data];
  const packageWeb = [...props.packageWebsite.data];
  var packageShow = [];
  packages.forEach((pack) => {
    var all = packageWeb.filter((data) => data.packageId === pack.id);
    pack.packageWebsite = [...all];
    if (pack.packageWebsite.length > 0) {
      packageandweb.push(pack);
    }
  });

  packageandweb.forEach((pack) => {
    if (
      packageShow.indexOf(pack.packageRegions[0].countryId) === -1 &&
      pack.packageWebsite[0].isHomePage === true
    ) {
      packageShow.push(pack.id);
    }
  });

  var brochures = [...brochuresArray];
  var brochureShow = [];
  brochures.forEach((bro) => {
    if (bro.isHomeShown == true) {
      brochureShow.push(bro);
    }
  });

  var Articles = [...articlesArray];
  var articleShow = [];
  Articles.forEach((art) => {
    if (art.isHomeShown == true) {
      articleShow.push(art);
    }
  });

  const [searchPackage, setsearchPackage] = useState("");
  const [dropPackage, getdropPackage] = useState([]);
  const [packageSearchLoading, setPackageSearchLoading] = useState(false);
  const [packageSearchInProgress, setPackageSearchInProgress] = useState(false);

  async function handleSearch(event) {
    setsearchPackage(event.target.value);
    if (!packageSearchInProgress) {
      setPackageSearchLoading(true);
      setPackageSearchInProgress(true);
      setTimeout(() => {
        setPackageSearchInProgress(false);
      }, 150);
      const alldata = await axiosconfig.get("inventory/package", {
        params: {
          SearchString: searchPackage,
          IsWebsite: true,
        },
      });
      const packageData = await alldata.data;
      setPackageSearchLoading(false);
      getdropPackage(packageData.data);
    }
  }

  function resetPackageSearchResults() {
    setTimeout(() => {
      getdropPackage([]);
    }, 150);
  }

  const [destSelect, setdestSelect] = useState({ id: "", slug: "" });
  const [packageSelect, getpackageSelect] = useState([]);
  const [packageType, getpackageType] = useState("");

  /* Website Search Logic */

  const [websiteSearchInput, setWebsiteSearchInput] = useState("");
  const [websiteRegionsResult, setWebsiteRegionsResult] = useState([]);
  const [websiteDestinationsResult, setWebsiteDestinationsResult] = useState(
    []
  );
  const [websitePackagesResult, setWebsitePackagesResult] = useState([]);
  const [websiteArticlesResult, setWebsiteArticlesResult] = useState([]);
  const [websiteSearchLoading, setWebsiteSearchLoading] = useState(false);
  const [queryInProgress, setQueryInProgress] = useState(false);

  const handleWebsiteSearchInputChange = async (value) => {
    setWebsiteSearchInput(value);
    if (!queryInProgress) {
      setQueryInProgress(true);
      setTimeout(() => {
        setQueryInProgress(false);
      }, 120);
      if (!websiteSearchInput) {
        setWebsiteSearchLoading(true);
        resetWebsiteSearchData();
        setWebsiteSearchLoading(false);
      } else {
        setWebsiteSearchLoading(true);
        const regions = await axiosconfig.get("foundation/region", {
          params: {
            SearchString: value,
          },
        });
        const destinations = await axiosconfig.get(
          "foundation/country/getcountriesinregions",
          {
            params: {
              SearchString: value,
            },
          }
        );
        const packages = await axiosconfig.get("inventory/package", {
          params: {
            SearchString: value,
            IsWebsite: true,
          },
        });
        const articles = await axiosconfig.get("website/article", {
          params: {
            SearchString: value,
          },
        });
        setWebsiteRegionsResult(regions.data.data);
        setWebsiteDestinationsResult(destinations.data.data);
        setWebsitePackagesResult(packages.data.data);
        setWebsiteArticlesResult(articles.data.data);
        setWebsiteSearchLoading(false);
      }
      setQueryInProgress(false);
    }
  };

  const resetWebsiteSearchData = () => {
    setTimeout(() => {
      setWebsiteRegionsResult([]);
      setWebsiteDestinationsResult([]);
      setWebsitePackagesResult([]);
      setWebsiteArticlesResult([]);
    }, 150);
  };

  useEffect(() => {
    getPackageType();
  }, [destSelect]);

  const handleDestination = (event) => {
    if (event.target.value?.length > 0) {
      setdestSelect(JSON.parse(event.target.value));
    }

    console.log(event.target.value);
    getpackageSelect([]);
    getpackageType("");

    getpackageType([]);
    getpackageType("");
  };

  const getPackageType = async () => {
    const alldata = await axiosconfig.get("inventory/package", {
      params: {
        CountryId: destSelect?.id,
        IsWebsite: true,
      },
    });
    const packageData = await alldata.data;
    var packagesTypes = packageData.data;
    var uniqueObjects = [
      ...new Map(
        packagesTypes.map((item) => [
          item.packageTypeName,
          item.packageTypeName,
        ])
      ).values(),
    ];
    getpackageSelect(uniqueObjects);
  };

  const handlePackageType = (event) => {
    getpackageType(event.target.value);
  };

  const [regionSelection, setregionSelection] = useState({ id: "", slug: "" });
  const [destSelection, setdestSelection] = useState({ id: "", slug: "" });
  const [daySelectionFrom, setdaySelectionFrom] = useState();
  const [daySelectionTo, setdaySelectionTo] = useState();
  const [packageDay, setpackageDay] = useState([]);
  const [budgetFromSelection, setbudgetFromSelection] = useState("");
  const [budgetToSelection, setbudgetToSelection] = useState("");
  const [packageBudget, setpackageBudget] = useState([]);
  const [packageBudgetFrom, setpackageBudgetFrom] = useState([]);

  const handleRegionChange = (event) => {
    if (event.target.value?.length > 0) {
      setregionSelection(JSON.parse(event.target.value));
    }

    setdestSelection("");
    setdaySelectionFrom();
    setdaySelectionTo();

    setbudgetFromSelection("");
    setbudgetToSelection("");
  };
  const handleDestChange = (event) => {
    if (event.target.value?.length > 0) {
      setdestSelection(JSON.parse(event.target.value));
    }

    setdaySelectionFrom();
    setdaySelectionTo();

    setbudgetFromSelection("");
    setbudgetToSelection("");
  };
  function handleDaysChange(event) {
    setdaySelectionFrom(
      event.target[event.target.selectedIndex].getAttribute("data-From")
    );
    setdaySelectionTo(
      event.target[event.target.selectedIndex].getAttribute("data-To")
    );
    setbudgetFromSelection("");
    setbudgetToSelection("");
  }
  function handleBudgetChange(event) {
    setbudgetFromSelection(
      event.target[event.target.selectedIndex].getAttribute("data-From")
    );
    setbudgetToSelection(
      event.target[event.target.selectedIndex].getAttribute("data-To")
    );
  }

  const router = useRouter();

  useEffect(() => {
    handleRouter();
  }, []);

  function handleRouter() {
    if (
      regionSelection?.id?.length > 0 &&
      destSelection?.id?.length > 0 &&
      daySelectionFrom?.length > 0 &&
      budgetFromSelection?.length > 0
    ) {
      router.push(
        `/destinations/${destSelection.slug}?NightFrom=${daySelectionFrom}&NightTo=${daySelectionTo}&BudgetFrom=${budgetFromSelection}&BudgetTo=${budgetToSelection}`
      );
    } else if (
      regionSelection?.id?.length > 0 &&
      destSelection?.id?.length > 0 &&
      daySelectionFrom?.length > 0
    ) {
      router.push(
        `/destinations/${destSelection.slug}?NightFrom=${daySelectionFrom}&NightTo=${daySelectionTo}`
      );
    } else if (
      regionSelection?.id?.length > 0 &&
      destSelection?.id?.length > 0 &&
      budgetFromSelection?.length > 0
    ) {
      router.push(
        `/destinations/${destSelection.slug}?&BudgetFrom=${budgetFromSelection}&BudgetTo=${budgetToSelection}`
      );
    } else if (
      regionSelection?.id?.length > 0 &&
      destSelection?.id?.length > 0
    ) {
      router.push(`/destinations/${destSelection.slug}`);
    } else if (regionSelection?.id?.length > 0) {
      router.push(`/region/${regionSelection.slug}`);
    }
  }

  return (
    <>
      <Head>
        <title>
          Central Holidays | Travel Packages | Affordable Tour Operator | Egypt
          Packages | Italy Packages | Europe Packages, | Africa Packages{" "}
        </title>
        <meta name="description" content="Generated by create next app" />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap"
          rel="stylesheet"
        />
      </Head>
      <main id="content" className="site-main">
        <section className="home-slider-section">
          <Slider className="home-slider" {...homeSlider}>
            {props.sliderData.data.map((slider) => {
              return (
                <>
                  <div
                    key={slider.id}
                    className={`home-banner-items order-${slider.order}`}
                  >
                    <div
                      className="banner-inner-wrap"
                      style={{
                        backgroundImage: `url(${slider.photoURL.replace(
                          / /g,
                          "%20"
                        )})`,
                        backgroundPosition: "top center",
                        backgroundAttachment: "fixed",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                    <div className="banner-content-wrap">
                      <div className="container">
                        <div className="banner-content text-center">
                          <h2 className="banner-title">{slider.title}</h2>
                          {slider?.packageName?.length > 0 && (
                            <Link
                              href={`package/${slider.packageName
                                .replace(
                                  /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                                  ""
                                )
                                .replace(/\s+/g, " ")
                                .replace(" ", "-")
                                .split(" ")
                                .join("-")}`}
                            >
                              <div
                                className={
                                  classes.slider_main_btn +
                                  " " +
                                  "button-primary"
                                }
                              >
                                {" "}
                                more details{" "}
                              </div>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="overlay"></div>
                  </div>
                </>
              );
            })}
          </Slider>
        </section>
        <div className="trip-search-section shape-search-section">
          <div className="slider-shape"></div>
          <div className="container">
            <div className="trip-search-inner white-bg d-flex">
              <div
                className={
                  classes.search_tabs_container + " " + "search-tabs-container"
                }
              >
                <ul className="nav nav-tabs" id="searchTabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="website-tab"
                      data-toggle="tab"
                      href="#website"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      {props?.pageSections?.data[0]?.searchFormData?.title1
                        ? props?.pageSections?.data[0]?.searchFormData?.title1
                        : "Website Search"}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="tours-tab"
                      data-toggle="tab"
                      href="#tours"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      {props?.pageSections?.data[0]?.searchFormData?.title2
                        ? props?.pageSections?.data[0]?.searchFormData?.title2
                        : "Search Tours"}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="vacation-tab"
                      data-toggle="tab"
                      href="#vacation"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      {props?.pageSections?.data[0]?.searchFormData?.title3
                        ? props?.pageSections?.data[0]?.searchFormData?.title3
                        : "Find My Vacation"}
                    </a>
                  </li>
                </ul>
                <div
                  className={classes.tab_content + " " + "tab-content"}
                  id="searchTabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="website"
                    role="tabpanel"
                    aria-labelledby="website-tab"
                  >
                    <div className="row">
                      <div className="col-sm-9">
                        <div
                          className={
                            classes.website_search_wrapper +
                            " " +
                            "input-group w-100 px-0"
                          }
                        >
                          <input
                            type="text"
                            name="wkey"
                            placeholder="Enter Search Keyword"
                            value={websiteSearchInput}
                            onChange={(event) =>
                              handleWebsiteSearchInputChange(event.target.value)
                            }
                            onBlur={resetWebsiteSearchData}
                          />
                          <div
                            className={classes.website_search_results_container}
                          >
                            {!websiteSearchLoading ? (
                              <div className="content_wrapper">
                                {websiteRegionsResult.length > 0 && (
                                  <div className={classes.website_result_group}>
                                    <h6>Regions</h6>
                                    <ul>
                                      {websiteRegionsResult?.map((el) => {
                                        return (
                                          <>
                                            {props.regions.data.map((reg) => {
                                              return (
                                                <>
                                                  {el.id == reg.id && (
                                                    <li key={el.id}>
                                                      <Link
                                                        href={`/region/${el.name
                                                          .replace(
                                                            /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                                                            ""
                                                          )
                                                          .replace(/\s+/g, " ")
                                                          .replace(" ", "-")
                                                          .split(" ")
                                                          .join("-")}`}
                                                      >
                                                        {el.name}
                                                      </Link>
                                                    </li>
                                                  )}
                                                </>
                                              );
                                            })}
                                          </>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                )}

                                {websiteDestinationsResult.length > 0 && (
                                  <div className={classes.website_result_group}>
                                    <h6>Destinations</h6>
                                    <ul>
                                      {websiteDestinationsResult?.map((el) => {
                                        return (
                                          <>
                                            {props.regions.data.map((reg) => {
                                              return (
                                                <>
                                                  {reg.countriesId.map(
                                                    (coun) => {
                                                      return (
                                                        <>
                                                          {el.id == coun && (
                                                            <li key={el.id}>
                                                              <Link
                                                                href={`/destinations/${el.nameCountry
                                                                  .replace(
                                                                    /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                                                                    ""
                                                                  )
                                                                  .replace(
                                                                    /\s+/g,
                                                                    " "
                                                                  )
                                                                  .replace(
                                                                    " ",
                                                                    "-"
                                                                  )
                                                                  .split(" ")
                                                                  .join("-")}`}
                                                              >
                                                                {el.nameCountry}
                                                              </Link>
                                                            </li>
                                                          )}
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </>
                                              );
                                            })}
                                          </>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                )}

                                {websitePackagesResult.length > 0 && (
                                  <div className={classes.website_result_group}>
                                    <h6>Packages</h6>
                                    <ul>
                                      {websitePackagesResult?.map((el) => {
                                        return (
                                          <li key={el.id}>
                                            <Link
                                              href={`/package/${el.packageName
                                                .replace(
                                                  /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                                                  ""
                                                )
                                                .replace(/\s+/g, " ")
                                                .replace(" ", "-")
                                                .split(" ")
                                                .join("-")}`}
                                            >
                                              {el.packageName}
                                            </Link>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                )}

                                {websiteArticlesResult.length > 0 && (
                                  <div className={classes.website_result_group}>
                                    <h6>Articles</h6>
                                    <ul>
                                      {websiteArticlesResult?.map((el) => {
                                        return (
                                          <li key={el.id}>
                                            <Link
                                              href={`/Article/${
                                                el?.pageSEO?.pageURL
                                                  ? el?.pageSEO?.pageURL
                                                  : el.title
                                                      .replace(
                                                        /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                                                        ""
                                                      )
                                                      .replace(/\s+/g, " ")
                                                      .replace(" ", "-")
                                                      .split(" ")
                                                      .join("-")
                                              }`}
                                            >
                                              {el.title}
                                            </Link>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className={classes.loading_wrapper}>
                                <img src="/loader.gif" alt="Loading..." />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div
                          className={
                            classes.website_search_btn +
                            " " +
                            "input-group w-100 h-100"
                          }
                        >
                          {websiteSearchInput ? (
                            <Link
                              href={{
                                pathname: "website/search",
                                query: {
                                  searchKey: websiteSearchInput,
                                },
                              }}
                            >
                              SEARCH NOW
                            </Link>
                          ) : (
                            <button disabled={true}>SEARCH NOW</button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="tours"
                    role="tabpanel"
                    aria-labelledby="tours-tab"
                  >
                    <div className="form-wrapper">
                      <div className="row no-gutters">
                        <div className="col-lg-3">
                          <div
                            className="input-group w-100 px-0"
                            style={{ position: "relative" }}
                          >
                            <input
                              type="text"
                              name="package"
                              placeholder="Enter Package Name"
                              value={searchPackage}
                              onChange={(event) => handleSearch(event)}
                              onBlur={resetPackageSearchResults}
                            />
                            <div
                              className={
                                classes.website_search_results_container
                              }
                            >
                              {!packageSearchLoading ? (
                                <div className="content_wrapper">
                                  {dropPackage?.length > 0 && (
                                    <div
                                      className={classes.website_result_group}
                                    >
                                      <h6>Packages Result</h6>
                                      <ul>
                                        {dropPackage?.map((pack) => {
                                          return (
                                            <li key={pack.id}>
                                              <Link
                                                href={`/package/${pack.packageName
                                                  .replace(
                                                    /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                                                    ""
                                                  )
                                                  .replace(/\s+/g, " ")
                                                  .replace(" ", "-")
                                                  .split(" ")
                                                  .join("-")}`}
                                              >
                                                {pack.packageName}
                                              </Link>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className={classes.loading_wrapper}>
                                  <img src="/loader.gif" alt="Loading..." />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="input-group w-100">
                            <select
                              required=""
                              name="city"
                              onChange={handleDestination}
                            >
                              <option value="">Select Destination</option>
                              {props.regions.data.map((region) => {
                                return (
                                  <>
                                    {region.countriesId.map((id, i) => {
                                      return (
                                        <>
                                          <option
                                            value={`{"id":"${id}", "slug":"${region.countriesNames[
                                              i
                                            ]
                                              .replace(
                                                /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                                                ""
                                              )
                                              .replace(/\s+/g, " ")
                                              .replace(" ", "-")
                                              .split(" ")
                                              .join("-")}"}`}
                                          >
                                            {region.countriesNames[i]}
                                          </option>
                                        </>
                                      );
                                    })}
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="input-group w-100">
                            <select
                              name="region"
                              value={packageType}
                              onChange={handlePackageType}
                              disabled={
                                packageTypes.length < 1 ||
                                packageSelect.length < 1
                              }
                            >
                              <option value="">Select Package Type </option>
                              {destSelect?.id?.length < 1 &&
                              packageSelect.length < 1 ? (
                                <>
                                  {packageTypes.map((type) => {
                                    return (
                                      <>
                                        <option value={type.name}>
                                          {type.name}
                                        </option>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  {packageSelect.map((type) => {
                                    return (
                                      <>
                                        <option value={type}>{type}</option>
                                      </>
                                    );
                                  })}
                                </>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          {destSelect?.slug?.length > 0 &&
                          packageType.length > 0 ? (
                            <Link
                              href={`/destinations/${
                                destSelect?.slug
                              }?packageType=${packageType.replace("&", "%26")}`}
                            >
                              <div className="input-group w-100">
                                <label className="screen-reader-text">
                                  {" "}
                                  Search{" "}
                                </label>
                                <input
                                  type="submit"
                                  name="travel-search"
                                  value="SEARCH NOW"
                                />
                              </div>
                            </Link>
                          ) : destSelect?.id?.length < 1 &&
                            packageType.length > 0 ? (
                            <Link
                              href={`/website/search?searchKey=${packageType.replace(
                                "&",
                                "%26"
                              )}`}
                            >
                              <div className="input-group w-100">
                                <label className="screen-reader-text">
                                  {" "}
                                  Search{" "}
                                </label>
                                <input
                                  type="submit"
                                  name="travel-search"
                                  value="SEARCH NOW"
                                />
                              </div>
                            </Link>
                          ) : destSelect?.slug?.length > 0 ? (
                            <Link href={`/destinations/${destSelect?.slug}`}>
                              <div className="input-group w-100">
                                <label className="screen-reader-text">
                                  {" "}
                                  Search{" "}
                                </label>
                                <input
                                  type="submit"
                                  name="travel-search"
                                  value="SEARCH NOW"
                                />
                              </div>
                            </Link>
                          ) : (
                            <div className="input-group w-100">
                              <label className="screen-reader-text">
                                {" "}
                                Search{" "}
                              </label>
                              <input
                                className={classes.search_tours_btn}
                                disabled={!searchPackage}
                                type="submit"
                                name="travel-search"
                                value="SEARCH NOW"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="vacation"
                    role="tabpanel"
                    aria-labelledby="vacation-tab"
                  >
                    <div className="form-wrapper">
                      <div className="row no-gutters">
                        <div className="col-xl-3 col-lg-4">
                          <div className="input-group w-100">
                            <select
                              required=""
                              name="city"
                              onChange={handleRegionChange}
                            >
                              <option value="">Select Region</option>
                              {props.regions.data.map((region) => {
                                return (
                                  <>
                                    <option
                                      value={`{"id":"${
                                        region.id
                                      }" , "slug":"${region.name
                                        .replace(
                                          /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                                          ""
                                        )
                                        .replace(/\s+/g, " ")
                                        .replace(" ", "-")
                                        .split(" ")
                                        .join("-")}"}`}
                                    >
                                      {region.name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4">
                          <div className="input-group w-100">
                            <select
                              required=""
                              name="city"
                              onChange={handleDestChange}
                              disabled={regionSelection?.id.length < 1}
                            >
                              <option value="">Select Destination</option>
                              {props.regions.data.map((region) => {
                                return (
                                  <>
                                    {region.id == regionSelection.id && (
                                      <>
                                        {region.countriesId.map((id, i) => {
                                          return (
                                            <>
                                              <option
                                                value={`{"id":"${id}" , "slug":"${region.countriesNames[
                                                  i
                                                ]
                                                  .replace(
                                                    /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                                                    ""
                                                  )
                                                  .replace(/\s+/g, " ")
                                                  .replace(" ", "-")
                                                  .split(" ")
                                                  .join("-")}"}`}
                                              >
                                                {region.countriesNames[i]}
                                              </option>
                                            </>
                                          );
                                        })}
                                      </>
                                    )}
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-4">
                          <div className="input-group w-100">
                            <select
                              required=""
                              name="city"
                              onChange={handleDaysChange}
                              disabled={
                                regionSelection.id?.length < 1 ||
                                destSelection?.id?.length < 1
                              }
                            >
                              <option value="">Select Nights</option>
                              <option data-From="0" data-To="6">
                                {"0"} - {"6"} {"Nights"}
                              </option>
                              <option data-From="7" data-To="9">
                                {"7"} - {"9"} {"Nights"}
                              </option>
                              <option data-From="9" data-To="">
                                {"9"}
                                {" +"} {"Nights"}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-4">
                          <div className="input-group w-100">
                            <select
                              required=""
                              name="city"
                              onChange={handleBudgetChange}
                              disabled={
                                regionSelection.id?.length < 1 ||
                                destSelection?.id?.length < 1
                                // ||packageBudget.length < 1
                              }
                            >
                              <option value="">Select Budget</option>
                              <option data-From="0" data-To="1000">
                                {"$0"} - {"$1000"}
                              </option>
                              <option data-From="1001" data-To="2000">
                                {"$1001"} - {"$2000"}
                              </option>
                              <option data-From="2001" data-To="3000">
                                {"$2001"} - {"$3000"}
                              </option>
                              <option data-From="3001" data-To="4000">
                                {"$3001"} - {"$4000"}
                              </option>
                              <option data-From="4001" data-To="">
                                {"$4001"} {" +"}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-4">
                          <div
                            className="input-group w-100"
                            onClick={handleRouter}
                          >
                            <label className="screen-reader-text">
                              {" "}
                              Search{" "}
                            </label>
                            <input
                              className={classes.search_vacations_btn}
                              type="submit"
                              name="travel-search"
                              value="SEARCH NOW"
                              disabled={
                                !regionSelection.id && !destSelection?.id
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {destShow?.length > 0 && (
          <section className="destination-section">
            <div className="container">
              <div className="section-heading text-center">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2">
                    {props.pageSections.data[0].topDestinationData.subTitle
                      ?.length > 0 && (
                      <h5 className="dash-style">
                        {
                          props.pageSections.data[0].topDestinationData
                            ?.subTitle
                        }
                      </h5>
                    )}
                    <h2>
                      {props.pageSections.data[0].topDestinationData?.title}
                    </h2>
                    {props.pageSections.data[0].topDestinationData?.description
                      ?.length > 0 && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            props.pageSections.data[0].topDestinationData
                              ?.description,
                        }}
                      ></p>
                    )}
                  </div>
                </div>
              </div>
              <div className="destination-inner destination-three-column">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="row">
                      {props.regions.data.map((region) => {
                        return (
                          <>
                            {region.countriesId.map((id, i) => {
                              return (
                                <>
                                  {destShow.slice(0, 4).map((dest) => {
                                    return (
                                      <>
                                        {id === dest.destinationId &&
                                          dest.homePageOrder == 1 && (
                                            <DestinationCard1
                                              key={dest.id}
                                              name={region.countriesNames[i]}
                                              img={dest.coverPhoto}
                                              destId={dest.destinationId}
                                            />
                                          )}
                                      </>
                                    );
                                  })}
                                </>
                              );
                            })}
                          </>
                        );
                      })}
                      {props.regions.data.map((region) => {
                        return (
                          <>
                            {region.countriesId.map((id, i) => {
                              return (
                                <>
                                  {destShow.slice(0, 4).map((dest) => {
                                    return (
                                      <>
                                        {id === dest.destinationId &&
                                          dest.homePageOrder == 2 && (
                                            <DestinationCard1
                                              key={dest.id}
                                              name={region.countriesNames[i]}
                                              img={dest.coverPhoto}
                                              destId={dest.destinationId}
                                            />
                                          )}
                                      </>
                                    );
                                  })}
                                </>
                              );
                            })}
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="row">
                      {props.regions.data.map((region) => {
                        return (
                          <>
                            {region.countriesId.map((id, i) => {
                              return (
                                <>
                                  {destShow.slice(0, 4).map((dest) => {
                                    return (
                                      <>
                                        {id === dest.destinationId &&
                                          dest.homePageOrder == 3 && (
                                            <DestinationCard2
                                              key={dest.id}
                                              name={region.countriesNames[i]}
                                              img={dest.coverPhoto}
                                              destId={dest.destinationId}
                                            />
                                          )}
                                      </>
                                    );
                                  })}
                                </>
                              );
                            })}
                          </>
                        );
                      })}
                      {props.regions.data.map((region) => {
                        return (
                          <>
                            {region.countriesId.map((id, i) => {
                              return (
                                <>
                                  {destShow.slice(0, 4).map((dest) => {
                                    return (
                                      <>
                                        {id === dest.destinationId &&
                                          dest.homePageOrder == 4 && (
                                            <DestinationCard2
                                              key={dest.id}
                                              name={region.countriesNames[i]}
                                              img={dest.coverPhoto}
                                              destId={dest.destinationId}
                                            />
                                          )}
                                      </>
                                    );
                                  })}
                                </>
                              );
                            })}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {packageShow?.length > 0 && (
          <section className="package-section">
            <div className="container">
              <div className="section-heading text-center">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2">
                    {props.pageSections.data[0].popularPackageData.subTitle
                      ?.length > 0 && (
                      <h5 className="dash-style">
                        {
                          props.pageSections.data[0].popularPackageData
                            ?.subTitle
                        }
                      </h5>
                    )}
                    <h2>
                      {props.pageSections.data[0].popularPackageData?.title}
                    </h2>
                    {props.pageSections.data[0].popularPackageData?.description
                      .length > 0 && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            props.pageSections.data[0].popularPackageData
                              ?.description,
                        }}
                      ></p>
                    )}
                  </div>
                </div>
              </div>
              <div className="package-inner">
                <div className="row">
                  {props.packages.data.map((pack) => {
                    return (
                      <>
                        {packageShow
                          .sort(() => 0.5 - Math.random())
                          .slice(0, 3)
                          .map((packweb) => {
                            return (
                              <>
                                {pack.id === packweb && (
                                  <PopularPackageCard
                                    key={pack.id}
                                    name={pack.packageName}
                                    price={pack.rate.startingFrom}
                                    img={
                                      pack.photoGallery !== null &&
                                      pack.photoGallery[0]
                                        ? pack.photoGallery[0].url
                                        : "https://www.centralholidays.com/MyUpload/1023201918184202020PACk850x530mag1.jpg"
                                    }
                                    city={pack.packageRegions[0].cityDays}
                                    description={pack.description}
                                    country={pack.packageRegions[0].countryName}
                                    packId={pack.id}
                                  />
                                )}
                              </>
                            );
                          })}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        )}
        <section className="callback-section">
          <div className="container">
            <div className="row no-gutters align-items-center">
              <div className="col-lg-5">
                <div className="callback-img my-4">
                  {console.log(
                    "main",
                    props.pageSections.data[0].aboutUsData?.image,
                    pagecontainer?.pageContent?.headerPhoto
                  )}
                  <img
                    src={
                      props.pageSections.data[0].aboutUsData?.image ||
                      pagecontainer?.pageContent?.headerPhoto ||
                      ""
                    }
                    style={{ height: "550px", objectFit: "cover" }}
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="callback-inner">
                  <div className="section-heading section-heading-white">
                    {props.pageSections.data[0].aboutUsData?.subTitle?.length >
                      0 && (
                      <h5 className="dash-style">
                        <Link href={`/${pagecontainer?.pageSEO?.pageURL}`}>
                          {props.pageSections.data[0].aboutUsData?.subTitle}
                        </Link>
                      </h5>
                    )}
                    <h2>{props.pageSections.data[0].aboutUsData?.title}</h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          props.pageSections.data[0].aboutUsData?.description,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {brochureShow?.length > 0 && (
          <section className="special-section">
            <div className="container">
              <div className="section-heading text-center">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2">
                    {props.pageSections.data[0].brochureData.subTitle?.length >
                      0 && (
                      <h5 className="dash-style">
                        {props.pageSections.data[0].brochureData?.subTitle}
                      </h5>
                    )}
                    <h2>{props.pageSections.data[0].brochureData?.title}</h2>
                    {props.pageSections.data[0].brochureData?.description
                      ?.length > 0 && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            props.pageSections.data[0].brochureData
                              ?.description,
                        }}
                      ></p>
                    )}
                  </div>
                </div>
              </div>
              <div className="special-inner">
                <div className="row">
                  {brochureShow
                    .sort(() => 0.5 - Math.random())
                    .slice(0, 4)
                    .map((bro) => {
                      return (
                        <>
                          <BrochuresCard
                            key={bro.id}
                            title={bro.title}
                            photoURL={bro.photoURL}
                            pdf={bro.pdfUrl}
                          />{" "}
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </section>
        )}
        <SubscribeSection />
        {articleShow?.length > 0 && (
          <section className="blog-section">
            <div className="container">
              <div className="section-heading text-center">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2">
                    {props.pageSections.data[0].blogData.subTitle?.length >
                      0 && (
                      <h5 className="dash-style">
                        {props.pageSections.data[0].blogData?.subTitle}
                      </h5>
                    )}
                    <h2>{props.pageSections.data[0].blogData?.title}</h2>
                    {props.pageSections.data[0].blogData?.description?.length >
                      0 && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            props.pageSections.data[0].blogData?.description,
                        }}
                      ></p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                {articleShow
                  .sort(() => 0.5 - Math.random())
                  .slice(0, 3)
                  .map((art) => {
                    return (
                      <>
                        <BlogCard
                          key={art.id}
                          content={art.pageContent.title}
                          date={art.createdDate}
                          img={art.pageContent.headerPhoto}
                          pageURL={art.pageSEO?.pageURL}
                          artId={art.id}
                        />
                      </>
                    );
                  })}
              </div>
            </div>
          </section>
        )}
        {testShow?.length > 0 && (
          <div className="testimonial-section">
            <div className="container">
              <div className="section-heading text-center">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2">
                    {props.pageSections.data[0].testimonialData.subTitle
                      ?.length > 0 && (
                      <h5 className="dash-style">
                        {props.pageSections.data[0].testimonialData?.subTitle}
                      </h5>
                    )}
                    <h2>{props.pageSections.data[0].testimonialData?.title}</h2>
                    {props.pageSections.data[0].testimonialData?.description
                      ?.length > 0 && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            props.pageSections.data[0].testimonialData
                              ?.description,
                        }}
                      ></p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <Slider
                    className="testimonial-inner testimonial-slider"
                    {...testimonialSlider}
                  >
                    {testShow
                      .sort(() => 0.5 - Math.random())
                      .slice(0, 3)
                      .map((test) => {
                        return (
                          <>
                            <TestimonialCard
                              key={test.id}
                              name={test.signature}
                              description={test.description}
                              createdDate={test.createdDate}
                            />
                          </>
                        );
                      })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        )}
        <ContentSection
          email={contactus?.email1}
          mainphone={contactus?.mainPhone}
          localphone={contactus?.localPhone}
          address={contactus?.fullAddress}
        />
        <ClientSection />
      </main>

      <a id="backTotop" href="#" className="to-top-icon">
        <i className="fas fa-chevron-up"></i>
      </a>
      <div className="header-search-form">
        <div className="container">
          <div className="header-search-container">
            <form className="search-form" role="search" method="get">
              <input type="text" name="s" placeholder="Enter your text..." />
            </form>
            <a href="#" className="search-close">
              <i className="fas fa-times"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export async function getServerSideProps() {
  const slider = await axiosconfig.get("website/homepageslider");
  const sliderData = await slider.data;

  const respone = await axiosconfig.get(
    "foundation/region/regionswithpackages/61d58047cd155a856c9d4848",
    {
      params: {
        PageSize: 500,
      },
    }
  );
  const regions = await respone.data;

  const res = await axiosconfig.get("website/websitedestination/mini", {
    params: {
      PageSize: 300,
    },
  });
  const destinations = await res.data;

  const pack = await axiosconfig.get("inventory/package/miniwebsite", {
    params: {
      PageSize: 500,
      isWebsite: true,
    },
  });
  const packages = await pack.data;

  const packweb = await axiosconfig.get("website/websitepackage", {
    params: {
      PageSize: 500,
    },
  });
  const packageWebsite = await packweb.data;

  const sections = await axiosconfig.get("website/homepagesections");

  const pageSections = await sections.data;

  return {
    props: {
      sliderData,
      regions,
      destinations,
      packages,
      packageWebsite,
      pageSections,
    },
  };
}
